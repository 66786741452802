import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
      price: 1,
      per_shipping_count: 1,
      discount_type: 0,
      menus: [],
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.product', route: { name: 'product' } },
      ],
      listRoute: () => ({ name: 'product-list' }),
      afterCreateRoute: result => ({
        name: 'product-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.product',
      dataAction: {
        delete: false,
        back:false
      },
      defaultData: this._defaultData,
      metaAction: {},
      blocks: [
        {
          title: '商品資料',
          data: {
            name: {
              required: true,
              label: 'data.name',
              type: 'text',
              maxlength: 100,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            photos: {
              label: 'data.photo',
              type: 'photo',
              photoType: 'product',
              multiple: true,
              simpleMode: true,
              total: 3,
              isDraggable: false,
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, photoListEqual}: FormDataChangeHelperInterface) => {
                return photoListEqual(originalData, data) === false
              },
            },
            temperature: {
              label: '溫層',
              type: 'selection',
              multiple: undefined,
              clearable: true,
              optionTextKey: 'name',
              optionValueKey: 'id',
              returnObject: true,
              options: (formInfo: FormInfoInterface) => {
                return formInfo?.formMeta?.freightPriceList || [];
              },
              hasChanged: ({originalData, data, bothEmpty, arrayPropertyDiff}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            setSpec: {
              label: '商品規格',
              component: () => import('../components/productSetSpec/index.vue'),
            },
            setSku: {
              component: () => import('../components/productSetSku/index.vue'),
            },
            setAttr: {
              label: '商品屬性',
              component: () => import('../components/productSetAttr/index.vue'),
            },
            spec: {
              component: () => import('./productSpec.vue'),
            },
            menus: {
              label: '商品分類',
              type: 'selection',
              multiple: true,
              clearable: true,
              optionTextKey: 'name',
              optionValueKey: 'id',
              returnObject: true,
              options: (formInfo: FormInfoInterface) => {
                return formInfo?.formMeta?.productMenus || [];
                // if(!formInfo.formMeta) return []
                // if(!Array.isArray(formInfo.formMeta.productMenus)) return []
                // return formInfo.formMeta.productMenus
              },
              hasChanged: ({originalData, data, bothEmpty, arrayPropertyDiff}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            // categories: {
            //   label: 'data.categories',
            //   type: 'selection',
            //   disabled:true,
            //   optionMeta: item => ({ depth: item.depth }),
            //   // @ts-ignore
            //   // itemDisabled: (item, formMeta) => item.depth != this.vm.productCategoryMaxDepth,
            //   optionText: (itemName, item: AnyObject) => this.vm?.$helper.nestedSetNodeNamePrefix(item.name, item.depth),
            //   refreshCallback: async (formInfo: FormInfoInterface) => {
            //     if(!formInfo.formMeta) return
            //     const result = await formInfo.formMeta.loadCategories()
            //     this.vm?.$store.commit(`form/${this.formKey}/setMetaProperty`, {
            //       key: 'categories',
            //       value: !Array.isArray(result) ? [] : result,
            //     })
            //   },
            //   optionTextKey: 'name',
            //   optionValueKey: 'id',
            //   returnObject: true,
            //   options: (formInfo: FormInfoInterface) => {
            //     if(!formInfo.formMeta) return []
            //     if(!Array.isArray(formInfo.formMeta.categories)) return []
            //     return formInfo.formMeta.categories
            //   },
            //   hasChanged: ({originalData, data, bothEmpty, arrayPropertyDiff}: FormDataChangeHelperInterface) => {
            //     if(bothEmpty(originalData, data)) return false
            //     return window.eagleLodash.isEqual(originalData, data) === false
            //   },
            //   // 多選使用
            //   // hasChanged: ({originalData, data, bothEmpty, arrayPropertyDiff}: FormDataChangeHelperInterface) => {
            //   //   if(bothEmpty(originalData, data)) return false
            //   //   return arrayPropertyDiff(originalData, data, 'id')
            //   // },
            // },
            description: {
              label: 'product.data.description',
              type: 'textarea',
              maxlength: 500,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            unit: {
              grid: { xl: 12, lg: 12, md: 12 },
              component: () => import('./unit.vue'),
            },
            // 出貨單位
            // shipping_unit: {
            //   label: 'product.data.shipping_unit',
            //   type: 'selection',
            //   options: unitList,
            //   clearable: true,
            //   hasChanged({originalData, data, bothEmpty}: FormDataChangeHelperInterface) {
            //     // @ts-ignore
            //     vm.isTheSame()

            //     if(bothEmpty(originalData, data)) return false
            //     return window.eagleLodash.isEqual(originalData, data) === false
            //   },
            // },
            // // 計價單位
            // calculate_unit: {
            //   label: 'product.data.calculate_unit',
            //   type: 'selection',
            //   options: unitList,
            //   clearable: true,
            //   hasChanged({originalData, data, bothEmpty}: FormDataChangeHelperInterface) {
            //     // @ts-ignore
            //     vm.isTheSame()

            //     if(bothEmpty(originalData, data)) return false
            //     return window.eagleLodash.isEqual(originalData, data) === false
            //   },
            // },
            // // 數量/每出貨單位
            // per_shipping_count: {
            //   label: 'product.data.per_shipping_count',
            //   help: "假設每次出貨單以袋為基本單位，且每袋2斤，則此欄位需填入2)",
            //   type: 'text',
            //   inputType: 'number',
            //   disabled: (formInfo) => {
            //     if(!formInfo) return false
            //     if(!formInfo.formData) return false
            //     const {calculate_unit,shipping_unit } = formInfo.formData
            //     return calculate_unit == shipping_unit
            //   },
            //   hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
            //     if(bothEmpty(originalData, data)) return false
            //     return originalData != data
            //   },
            // },

            // // 單價
            // price: {
            //   label: 'product.data.price',
            //   type: 'text',
            //   inputType: 'number',
            //   hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
            //     if(bothEmpty(originalData, data)) return false
            //     return originalData != data
            //   },
            // },
            daily_alert: {
              label: 'product.data.daily_alert',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
            status: {
              label: 'data.status',
              type: 'switch',
              switchLabel: (value) => value == true ? '上架' : '下架',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
          },
        },

        {
          title: 'product.form_block.discount_config',
          data: {
            discount_config: {
              component: () => import('@/modules/product/components/discountConfig.vue'),
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return !window.eagleLodash.isEqual(originalData, data)
              },
            },
          }
        },

        // 商品主資料設定
        // {
        //   title: 'product.form_block.setup',
        //   data: {
        //     // 商品規格
        //     instance_config: {
        //       label: 'product.data.spec',
        //       grid: { xl: 12, lg: 12, md: 12 },
        //       create: (formInfo: FormInfoInterface) => {
        //         if(!formInfo.formData) return false
        //         return !!formInfo.formData.instance_config
        //       },
        //       component: () => import('./productForm/instanceConfig.vue'),
        //     },
        //     instances: {
        //       grid: { xl: 12, lg: 12, md: 12 },
        //       component: () => import('./productForm/instances.vue'),
        //       hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
        //         if(bothEmpty(originalData, data)) return false
        //         return window.eagleLodash.isEqual(originalData, data) === false
        //       },
        //     },
        //   },
        // },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()
